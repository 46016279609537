import { message } from "antd";
import React from "react";
import { IPlans } from "../types";
import {
  getBuilderEssentialPlanIds,
  getBuilderProfessionalPlanIds,
  getEssentialPlanIds,
  getProfessionalPlanIds,
  getEnterprisePlanIds,
  getCLIFounderPlanIds,
  getCLIPerformancePlanIds,
  getCLIScalePlanIds,
} from "@todesktop/shared";
import { stage } from "../firebase/config";
import { removeUIState } from "../actions";
import { history } from "../util";
import { store } from "../../store";

export type planTypes = "essential_new" | "professional" | "cli_founder";

const builderEssentialPlans = getBuilderEssentialPlanIds(stage);
const builderProfessionalPlans = getBuilderProfessionalPlanIds(stage);

export const builderEssentialPlanIds = Object.values(builderEssentialPlans);
export const builderProfessionalPlanIds = Object.values(
  builderProfessionalPlans
);

/* ToDesktop Web Plans */
const essentialPlans = getEssentialPlanIds(stage);
const professionalPlans = getProfessionalPlanIds(stage);
const enterprisePlans = getEnterprisePlanIds(stage);

export const essentialPlanIds = Object.values(essentialPlans);
export const professionalPlanIds = Object.values(professionalPlans);
export const organizationPlanIds = [];
export const enterprisePlanIds = Object.values(enterprisePlans);

/* ToDesktop CLI Plans */
const cliFounderPlans = getCLIFounderPlanIds(stage);
const cliPerformancePlans = getCLIPerformancePlanIds(stage);
const cliScalePlans = getCLIScalePlanIds(stage);

export const cliFounderPlanIds = Object.values(cliFounderPlans);
export const cliPerformancePlanIds = Object.values(cliPerformancePlans);
export const cliScalePlanIds = Object.values(cliScalePlans);

export const defaultEssentialPlan = essentialPlans.essential_new;

export type PlanGroupKey = "pro" | "performance" | "scale";
export type PlanGroupValue = { plans: IPlans[]; name: string };
export type PlanGroups = Record<PlanGroupKey, PlanGroupValue>;
export const planGroups: PlanGroups = {
  scale: {
    plans: ["organization", "enterprise", "cli_scale"],
    name: "Scale",
  },
  performance: {
    plans: [
      "professional",
      "organization",
      "enterprise",
      "cli_performance",
      "cli_scale",
      "builder_essential",
      "builder_professional",
    ],
    name: "Performance",
  },
  pro: {
    plans: [
      "professional",
      "organization",
      "enterprise",
      "cli_founder",
      "cli_performance",
      "cli_scale",
      "builder_professional",
    ],
    name: "Professional",
  },
};

export const getPayModalsInfo = (chosenPlan: planTypes) => {
  if (chosenPlan === "cli_founder") {
    return {
      title: "Begin Your Free Trial",
      afterClose: () => {
        removeUIState();
        history.push("/");
      },
      payButtonText: "Begin Free Trial",
      PriceAlert: (
        <>
          You will be charged $100/month <b>after</b> your 7 day trial
        </>
      ),
      afterPay: () => {
        message.success("Trial started");
        removeUIState();
      },
    };
  }
  return {
    title: "Begin Your Free Trial",
    afterClose: () => removeUIState(),
    payButtonText: "Begin Free Trial",
    PriceAlert: (
      <>
        You will be charged ${chosenPlan === "essential_new" ? 58 : 199}
        /month <b>after</b> your 7 day trial
      </>
    ),
    afterPay: () => {
      const { shouldCreateSubWithoutBuild } = store.getRawState();
      if (shouldCreateSubWithoutBuild) {
        message.success("Payment successful");
        removeUIState();
      }
    },
  };
};

export const getPlanIds = (plan: IPlans) => {
  switch (plan) {
    case "essential":
      return essentialPlanIds;
    case "professional":
      return professionalPlanIds;
    case "organization":
      return organizationPlanIds;
    case "enterprise":
      return enterprisePlanIds;
    case "cli_founder":
      return cliFounderPlanIds;
    case "cli_performance":
      return cliPerformancePlanIds;
    case "cli_scale":
      return cliScalePlanIds;
    case "builder_essential":
      return builderEssentialPlanIds;
    case "builder_professional":
      return builderProfessionalPlanIds;
    default:
      return professionalPlanIds;
  }
};

const planTitlesAndIds: { title: IPlans; ids: string[] }[] = [
  { title: "essential", ids: essentialPlanIds },
  { title: "professional", ids: professionalPlanIds },
  { title: "organization", ids: organizationPlanIds },
  { title: "enterprise", ids: enterprisePlanIds },
  { title: "cli_founder", ids: cliFounderPlanIds },
  { title: "cli_performance", ids: cliPerformancePlanIds },
  { title: "cli_scale", ids: cliScalePlanIds },
  { title: "builder_essential", ids: builderEssentialPlanIds },
  { title: "builder_professional", ids: builderProfessionalPlanIds },
];

export const getPlanTitle = (plan = defaultEssentialPlan): IPlans => {
  for (const { title, ids } of planTitlesAndIds) {
    const foundPlan = ids.find((id) => plan === id);

    if (foundPlan) {
      return title;
    }
  }
  return "essential";
};

export const professionalPlanFeatureKeys = [
  {
    key: "shouldCreateAppXFiles",
    value: "Create Windows App Store Artifacts",
  },
  {
    key: "shouldCreate32BitWindowsArtifacts",
    value: "Create 32 Bit Windows Artifacts",
  },
  {
    key: "shouldCreateDebianPackages",
    value: "Create Debian Packages",
  },
  {
    key: "shouldCreateMacAppStoreFiles",
    value: "Create Mac App Store Artifacts",
  },
  {
    key: "shouldCreateMacPKG",
    value: "Create Mac PKG installers",
  },
  {
    key: "shouldCreateMSIInstallers",
    value: "Create MSI Installers",
  },
  {
    key: "shouldCreateRPMPackages",
    value: "Create RPM Packages",
  },
  {
    key: "shouldCreateSnapFiles",
    value: "Create Snap Store files",
  },
  {
    key: "customDomain",
    value: "Use Custom Domain",
  },
];
