// modules
import css from "@emotion/css";
import React from "react";

// components
import { Tooltip } from "antd";
import { StarOutlined } from "@ant-design/icons";
import { useTheme } from "../../~reusables/contexts/ThemeContext";
import { IPlans } from "../../~reusables/types";
import { Heading } from "../atoms/Heading";
import { useStore, $hasFeatureAccess } from "../../store";

interface IPlanBadge {
  planName: string;
  featurePlans: IPlans[];
  tooltip?: string;
  showLabel?: boolean;
}

export const PlanBadge: React.FC<IPlanBadge> = ({
  planName,
  featurePlans,
  tooltip,
  showLabel = true,
}) => {
  const hasFeatureAccess = useStore($hasFeatureAccess(featurePlans));
  const { space } = useTheme();

  // if you don't have an active subscription or access to this feature, show the label
  if (!hasFeatureAccess) {
    return (
      <Tooltip
        title={
          tooltip || `This feature is only available on the ${planName} plan.`
        }
      >
        <div
          css={css`
            display: inline-flex;
            align-items: center;
          `}
        >
          <StarOutlined style={{ marginRight: space[3], color: "#f9c606" }} />
          {showLabel && (
            <Heading variant="h6" as="h6" color="#f9c606">
              {planName}
            </Heading>
          )}
        </div>
      </Tooltip>
    );
  }

  return null;
};
